<template>
  <section>
      <div class="message">
      <h2>404</h2>
      <h1>Page Not Found</h1>
      <p>
        The specified file was not found on this website. Please check the URL
        for mistakes and try again.
      </p>
      <div class="d-flex justify-content-center">
        <router-link :to="{ name: 'Home' }" class="btn btn-primary">HOMEPAGE</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style scoped>
section {
  position: relative;
  min-height: calc(100vh - 40px);
}

section .message {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 85%;
  width: 85%;
  max-height: 360px;
  max-width: 580px;
  box-shadow: 0 0 4px var(--bs-blue);
  border-radius: 4px;
  padding: 2em;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
}

section .message h2,
section .message h1,
section .message p,
section .message a {
  text-align: center;
}

section .message h2 {
  color: var(--bs-dark);
}

section .message h1 {
  color: var(--bs-blue);
}

section .message a {
  cursor: pointer;
  font-size: 18px;
  background-color: var(--bs-blue);
  color: #fff;
  padding: 8px 20px;
  border-radius: 8px;
  margin: 14px 0;
}

section .message a:hover {
  background-color: var(--bs-white);
  color: var(--bs-blue);
}
</style>